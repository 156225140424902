/* eslint-disable camelcase */
import isEmpty from 'lodash.isempty';

import { allowedUserRoles } from 'app/constants/userRoles';
import { decodeJwtToken } from './decodeJwtTokenUtils';
import { errorMessages } from 'app/constants/errorMessages';

export const decodeUserSession = (sessionId) => {
  const decodedUserSession = decodeJwtToken(sessionId);

  if (isEmpty(decodedUserSession)) {
    throw new Error(JSON.stringify(errorMessages.decodeSessionIdError));
  }

  const {
    custom_canvas_course_id,
    custom_canvas_roles,
    custom_canvas_user_login_id,
    custom_canvas_user_name,
    custom_canvas_user_email,
    exp
  } = decodedUserSession;

  if (
    isEmpty(custom_canvas_course_id) ||
    isEmpty(custom_canvas_roles) ||
    isEmpty(custom_canvas_user_login_id) ||
    isEmpty(custom_canvas_user_name) ||
    isEmpty(custom_canvas_user_email)
  ) {
    throw new Error(JSON.stringify(errorMessages.decodeSessionIdError));
  }

  const userRoles = custom_canvas_roles ? custom_canvas_roles.split(',') : [];

  if (!allowedUserRoles.some((role) => userRoles.includes(role))) {
    throw new Error(JSON.stringify(errorMessages.insufficientPrivileges));
  }

  const currentTime = Date.now();

  return {
    accessToken: sessionId,
    courseId: custom_canvas_course_id,
    expiresIn: exp,
    timeUntilSessionExpires: exp * 1000 - currentTime,
    userEmail: custom_canvas_user_email,
    userLoginId: custom_canvas_user_login_id,
    username: custom_canvas_user_name
  };
};
