import PropTypes from 'prop-types';
import React from 'react';

import { CUSTOM_TEMPLATE } from '../../app/constants/smsTemplates';
import { helpMessages } from 'app/constants/helpMessages';
import { replaceStringWithVars } from 'utils/dataUtils';
import { StyledFromName, StyledPreview } from './SMSPreview.styles';
import { useStore } from 'store';

const SMSPreview = ({ template, timeframe, firstName }) => {
  const customMessage = useStore((state) => state.smsStore.customMessage);
  const courseKey = useStore((state) => state.courseStore.courseKey);
  const getUserInfo = useStore((state) => state.authStore.getUserInfo);
  const { elaEmail: agentEmail, elaName: agentName, fromPhoneNumber } = getUserInfo();
  const { templates } = useStore((state) => state.smsTemplatesStore);

  const dynamicPreviewContent = replaceStringWithVars(templates[template].content, {
    firstName,
    agentEmail,
    agentName,
    fromPhoneNumber,
    unitName: courseKey && courseKey.toUpperCase(),
    callTimeframe: timeframe
  });

  const customMessageContent = replaceStringWithVars(customMessage, {
    firstName,
    agentEmail,
    agentName,
    fromPhoneNumber,
    unitName: courseKey && courseKey.toUpperCase(),
    callTimeframe: timeframe
  });

  return (
    <>
      <StyledFromName>From {helpMessages.smsFromPreview}</StyledFromName>
      <StyledPreview
        dangerouslySetInnerHTML={{
          __html: template === CUSTOM_TEMPLATE ? customMessageContent : dynamicPreviewContent
        }}
      />
    </>
  );
};

SMSPreview.propTypes = {
  template: PropTypes.string,
  timeframe: PropTypes.string,
  firstName: PropTypes.string
};

export { SMSPreview };

export default SMSPreview;
