import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import {
  StyledButton,
  StyledButtonWrapper,
  StyledFilterGrid,
  StyledFilterWrapper,
  StyledTabletActions
} from './Filters.styles';
import Container from 'components/Container';
import Grid from 'components/Grid';
import { Icon, ICON_TYPES } from 'components/Icon';
import { ConnectedMultiSelectFilter } from 'components/MultiSelectFilter/MultiSelectFilter';
import SimpleButton from 'components/SimpleButton';
import { ConnectedSearchSelections } from '../SearchSelections/ConnectedSearchSelections';
import SMSButton from 'components/SMSButton';
import ConnectedSortBy from 'components/SortBy/ConnectedSortBy';
import ConnectedTeachingPeriodSelect from 'components/TeachingPeriodSelect/ConnectedTeachingPeriodSelect';
import withStickyContainer from 'hocs/withStickyContainer';
import { useStore } from 'store';

const Filters = ({ isSticky, isDrawer, onCloseDrawer }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const { showFilterDrawer, showSearchDrawer } = useStore((state) => state.drawerStore);
  const attributesForFaceting = useStore((state) => state.algoliaStore.attributesForFaceting);

  useEffect(() => {
    if (!isTablet && isDrawer) {
      onCloseDrawer();
    }
  }, [isTablet, isDrawer, onCloseDrawer]);

  return (
    <Container disableGutters={!isSticky || isDrawer}>
      <StyledFilterGrid container spacing={1} drawer={isDrawer} sticky={isSticky}>
        {(isDrawer || !isTablet) &&
          attributesForFaceting.map((attribute) => {
            // handling Teaching Periods, which you can only select one option at a time
            if (attribute === attributesForFaceting[0]) {
              return (
                <StyledFilterWrapper item width={[1, 1, 1 / 5]} key={attribute.attribute}>
                  <ConnectedTeachingPeriodSelect
                    attribute={attribute.attribute}
                    label={attribute.label}
                  />
                </StyledFilterWrapper>
              );
            }

            return (
              <StyledFilterWrapper item width={[1, 1, 1 / 5]} key={attribute.attribute}>
                <ConnectedMultiSelectFilter
                  attribute={attribute.attribute}
                  label={attribute.label}
                />
              </StyledFilterWrapper>
            );
          })}

        {isDrawer && (
          <StyledFilterWrapper item width={[1, 1, 1 / 5]}>
            <SimpleButton
              onClick={onCloseDrawer}
              label="Hide filters"
              p={0}
              data-ref="hide-filters-drawer-button"
            />
          </StyledFilterWrapper>
        )}
        {isTablet && isSticky && !isDrawer && (
          <Grid container justifyContent="space-between" alignItems="flex-start" wrap="nowrap">
            <StyledTabletActions>
              <div>
                <StyledButton
                  size="large"
                  variant="contained"
                  disableElevation
                  onClick={showFilterDrawer}
                  startIcon={<Icon name={ICON_TYPES.FILTER} />}
                  data-ref="show-filters-drawer-button"
                >
                  <span>Filter</span>
                </StyledButton>
                <StyledButton
                  size="large"
                  variant="contained"
                  disableElevation
                  onClick={showSearchDrawer}
                  startIcon={<Icon name={ICON_TYPES.SEARCH} />}
                  data-ref="show-search-drawer-button"
                >
                  <span>Search</span>
                </StyledButton>
              </div>
              <ConnectedSortBy label="Sort" />
            </StyledTabletActions>
            {!isMobile && (
              <StyledButtonWrapper>
                <SMSButton size="medium" />
              </StyledButtonWrapper>
            )}
          </Grid>
        )}
        {!isTablet && isSticky && (
          <StyledFilterWrapper item width={[1, 1, 1 / 7]}>
            <StyledButtonWrapper>
              <SMSButton showSelectedLabel={false} size="medium" />
            </StyledButtonWrapper>
          </StyledFilterWrapper>
        )}
      </StyledFilterGrid>
      <ConnectedSearchSelections />
    </Container>
  );
};

Filters.propTypes = {
  isSticky: PropTypes.bool,
  isDrawer: PropTypes.bool,
  onCloseDrawer: PropTypes.func
};

Filters.defaultProps = {
  isSticky: false,
  isDrawer: false,
  onCloseDrawer: () => null
};

export default withStickyContainer(Filters);
