import React, { Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import ErrorHandler from 'components/ErrorHandler';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal from 'components/Modal';
import SessionToken from 'components/SessionToken';
import ThemeProvider from 'context/ThemeProvider';
import routes from './routes';
import Drawer from 'components/Drawer';
import reportWebVitals from '../reportWebVitals';
import InstantSearchProvider from '../context/InstantSearchProvider';

const history = createBrowserHistory();

function App() {
  return (
    <ThemeProvider>
      <Router history={history}>
        <SessionToken />
        <InstantSearchProvider>
          <Suspense fallback={<LoadingSpinner />}>
            <Switch>
              {routes.map((route, index) => (
                <Route key={index} {...route} />
              ))}
            </Switch>
          </Suspense>
          <Drawer />
          <Modal />
        </InstantSearchProvider>
        <ErrorHandler />
      </Router>
    </ThemeProvider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default App;
