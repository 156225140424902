export const SMS_REQUESTED = 1;
export const SMS_FAILED = 2;
export const CALL_SCHEDULED = 3;
export const CALL_TRY_AGAIN = 4;
export const CALL_FAILED = 5;
export const CALL_SUCCESS = 6;
export const NO_CALL_STATUS = 7;

export const callStatusLabels = {
  [SMS_REQUESTED]: 'SMS pending',
  [SMS_FAILED]: 'SMS failed',
  [CALL_SCHEDULED]: 'SMS Sent',
  [CALL_TRY_AGAIN]: 'Retry later',
  [CALL_FAILED]: 'No phone contact',
  [CALL_SUCCESS]: 'Contacted by phone',
  [NO_CALL_STATUS]: 'No call status'
};

export const getNoteDescription = (status, isFirstNote) => {
  if (isFirstNote && status !== NO_CALL_STATUS) {
    if (status === SMS_REQUESTED) {
      return 'SMS requested';
    }

    if (status === SMS_FAILED) {
      return 'SMS failed to send';
    }

    return 'Call status changed';
  }

  return 'Note added';
};

export default {
  SMS_REQUESTED,
  SMS_FAILED,
  CALL_SCHEDULED,
  CALL_TRY_AGAIN,
  CALL_FAILED,
  CALL_SUCCESS,
  NO_CALL_STATUS
};
