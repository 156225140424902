import isEmpty from 'lodash.isempty';
import isString from 'lodash.isstring';

import { apiEndpoints } from 'app/constants/apiEndpoints';
import { errorMessages } from '../app/constants/errorMessages';
import {
  DEFAULT_REPLICA,
  ATTRIBUTE_KEY_PERIOD_ID,
  searchSettings,
  ATTRIBUTE_KEY_STAFF_NAME
} from '../app/constants/search';

const createAlgoliaStore = (set, get) => ({
  algoliaKey: null,
  validUntil: null,
  indexName: null,
  applicationId: null,
  isFetchingAlgoliaKey: false,
  isKeyAvailable: true,
  attributesForFaceting: null,
  searchState: null,
  refinementState: [],
  queryState: '',

  sortBy: DEFAULT_REPLICA,
  sortByOptions: [],

  requireRefetch: false,

  activeFilters: [], // these are not facet filters, we pass these into <Configure />
  filterLabels: {},

  fetchAlgoliaKey: async () => {
    const { apiRequest, handleApiError } = get().actions;

    const response = await apiRequest(apiEndpoints.algoliaKey);

    if (response.status === 404) {
      set((state) => {
        state.algoliaStore.isFetchingAlgoliaKey = false;
        state.algoliaStore.isKeyAvailable = false;
        state.error = errorMessages.noTeachingPeriods;
      });
      return;
    }

    if (response.status !== 200) {
      // do we need a warning specifically for algolia?
      handleApiError(response.status);
      return;
    }

    const {
      publicKey,
      applicationId,
      // TODO: implement the valid until warning handling
      validUntil,
      indexName,
      settings: { replicas, attributesForFaceting }
    } = response.data;

    set((state) => {
      state.algoliaStore.isFetchingAlgoliaKey = true;
      state.algoliaStore.algoliaKey = publicKey;
      state.algoliaStore.applicationId = applicationId;
      state.algoliaStore.validUntil = validUntil;
      state.algoliaStore.indexName = indexName;
      state.algoliaStore.attributesForFaceting = attributesForFaceting;
      // eslint-disable-next-line prefer-destructuring
      state.algoliaStore.sortBy = replicas[0][1];
      state.algoliaStore.sortByOptions = replicas;
    });
  },

  setSortBy(sortBy) {
    const currentSortBy = get().algoliaStore.sortBy;

    if (typeof sortBy !== 'undefined' && sortBy !== null && currentSortBy !== sortBy) {
      set((state) => {
        state.algoliaStore.sortBy = sortBy;
      });
    }
  },

  updateRefinementState() {
    const { attributesForFaceting, searchState } = get().algoliaStore;
    const updatedRefinementState = attributesForFaceting.map(({ attribute }) => {
      return {
        attribute,
        list: isString(searchState.refinementList[attribute])
          ? [searchState.refinementList[attribute]]
          : searchState.refinementList[attribute]
      };
    });

    set((state) => {
      state.algoliaStore.refinementState = updatedRefinementState;
    });
  },

  updateQueryState() {
    const { searchState } = get().algoliaStore;

    set((state) => {
      state.algoliaStore.queryState = searchState.query ?? '';
    });
  },

  initSearchState() {
    const { setSearchState } = get().algoliaStore;
    const { staff, periods } = get().courseStore;

    const searchState = {
      refinementList: {
        ATTRIBUTE_KEY_PERIOD_ID: null
      },
      page: 1,
      configure: {
        hitsPerPage: searchSettings.resultsPerPage
      }
    };

    // Set the latest week
    searchState.refinementList[ATTRIBUTE_KEY_PERIOD_ID] =
      periods.length > 0 ? periods[0].periodId : null;

    // Automatically sets the Learning Advisor filter to logged-in user if enabled
    if (searchSettings.initUserStaffFilter) {
      const userEmail = get().authStore.getUserInfo().elaEmail || '';
      const userName = staff.find(
        (staffMember) => staffMember.email.toLowerCase() === userEmail.toLowerCase()
      )?.fullName;

      if (userName) {
        searchState.refinementList[ATTRIBUTE_KEY_STAFF_NAME] = [userName];
      }
    }

    setSearchState(searchState);
  },

  setSearchState(searchState) {
    const { updateQueryState, updateRefinementState } = get().algoliaStore;

    set((state) => {
      state.algoliaStore.searchState = searchState;
    });

    // Guard against an empty search state when widgets are unmounted
    if (!isEmpty(searchState.refinementList[ATTRIBUTE_KEY_PERIOD_ID])) {
      updateRefinementState();
      updateQueryState();
    }
  },

  triggerRefetch() {
    set((state) => {
      state.algoliaStore.requireRefetch = true;
    });

    // reset refetch, so a user can request again
    setTimeout(() => {
      set((state) => {
        state.algoliaStore.requireRefetch = false;
      });
    }, 5000);
  },

  setActiveFilters(filters) {
    set((state) => {
      state.algoliaStore.activeFilters = filters;
    });
  },

  removeFilter(filter) {
    const { activeFilters } = get().algoliaStore;

    const updatedFilters = activeFilters.filter(({ value }) => value !== filter);

    set((state) => {
      state.algoliaStore.activeFilters = updatedFilters;
    });
  },

  setFilterLabels(options) {
    set((state) => {
      state.algoliaStore.filterLabels = options;
    });
  }
});

export { createAlgoliaStore };
