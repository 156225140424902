const HIGH = 1;
const MEDIUM = 2;
const LOW = 3;

export const riskLevelLabels = {
  [HIGH]: 'High risk',
  [MEDIUM]: 'Medium risk',
  [LOW]: 'Low risk'
};

export const riskLevelLabelsShort = {
  [HIGH]: 'High',
  [MEDIUM]: 'Med',
  [LOW]: 'Low'
};

export default {
  HIGH,
  MEDIUM,
  LOW
};
