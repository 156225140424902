import algoliasearch from 'algoliasearch/lite';
import PropTypes from 'prop-types';
import React from 'react';
import { InstantSearch, Configure } from 'react-instantsearch-dom';

import LoadingSpinner from '../components/LoadingSpinner';
import PageLayout from '../components/PageLayout';
import { searchSettings } from '../app/constants/search';
import { useStore } from '../store';
import VirtualSearchWidgets from '../components/VirtualSearchWidgets';

const InstantSearchProvider = ({ children }) => {
  const {
    applicationId,
    algoliaKey,
    isFetchingAlgoliaKey,
    sortBy,
    requireRefetch,
    searchState,
    setSearchState
  } = useStore((state) => state.algoliaStore);

  const isFetchingCourseData = useStore((state) => state.courseStore.isFetchingCourseData);
  const isFetchingStudentsData = useStore((state) => state.studentsPIIStore.isFetchingStudentsData);

  const PageLoading = (
    <PageLayout>
      <LoadingSpinner />
    </PageLayout>
  );

  if (isFetchingAlgoliaKey && !algoliaKey) {
    return PageLoading;
  }

  if (isFetchingCourseData || isFetchingStudentsData) {
    return PageLoading;
  }

  const onSearchStateChange = (updatedSearchState) => {
    if (updatedSearchState.refinementList) {
      setSearchState(updatedSearchState);
    }
  };

  const searchClient = algoliasearch(applicationId, algoliaKey);

  return (
    <InstantSearch
      indexName={sortBy}
      searchClient={searchClient}
      searchState={searchState}
      stalledSearchDelay={100}
      refresh={requireRefetch}
      onSearchStateChange={onSearchStateChange}
    >
      <VirtualSearchWidgets />
      <Configure hitsPerPage={searchSettings.resultsPerPage} />
      {children}
    </InstantSearch>
  );
};

InstantSearchProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default InstantSearchProvider;
